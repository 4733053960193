import FileSaver from 'file-saver';
import XLSX from 'xlsx';

export function formatDate(date = new Date(), format) {
  const o = {
    'M+': date.getMonth() + 1, // 月份
    'd+': date.getDate(), // 日
    'H+': date.getHours(), // 小时
    'm+': date.getMinutes(), // 分
    's+': date.getSeconds(), // 秒
    'q+': Math.floor((date.getMonth() + 3) / 3), // 季度
    S: date.getMilliseconds(), // 毫秒
  };
  if (/(y+)/.test(format)) {
    // eslint-disable-next-line no-param-reassign
    format = format.replace(RegExp.$1, (`${date.getFullYear()}`).substr(4 - RegExp.$1.length));
  }
  // eslint-disable-next-line no-restricted-syntax
  for (const k of Object.keys(o)) {
    if (new RegExp(`(${k})`).test(format)) {
      // eslint-disable-next-line no-param-reassign
      format = format.replace(RegExp.$1, (RegExp.$1.length === 1) ? (o[k]) : ((`00${o[k]}`).substr((`${o[k]}`).length)));
    }
  }
  return format;
}

// 导出表格(el-table)
export function exportExcel(dom, name = 'download') {
  /* generate workbook object from table */
  const wb = XLSX.utils.table_to_book(dom); // 表格的id名
  /* get binary string as output */
  const wbout = XLSX.write(wb, { bookType: 'xlsx', bookSST: true, type: 'array' });
  try {
    FileSaver.saveAs(new Blob([wbout], { type: 'application/octet-stream' }), `${name}.xlsx`);
  } catch (e) {
    console.log(e);
  }
  return wbout;
}

// 导出原生表格
export function derivedTab(tab, name = 'download') {
  if (!tab) {
    return;
  }
  let tabText = "<table border='2px'><tr bgcolor='#87AFC6'>";
  for (let j = 0; j < tab.rows.length; j += 1) {
    tabText = `${tabText + tab.rows[j].innerHTML}</tr>`;
  }
  tabText = `${tabText}</table>`;
  tabText = tabText.replace(/<A[^>]*>|<\/A>/g, ''); // remove if u want links in your table
  tabText = tabText.replace(/<img[^>]*>/gi, ''); // remove if u want images in your table
  tabText = tabText.replace(/<input[^>]*>|<\/input>/gi, ''); // reomves input params
  const url = `data:application/vnd.ms-excel,${encodeURIComponent(tabText)}`;
  let downloadLink = document.createElement('a');
  downloadLink.href = url;
  downloadLink.download = `${name}.xls`;
  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);
  downloadLink = null;
}
export function getWeek(dowOffset) {
  const dateNow = new Date();
  // eslint-disable-next-line no-param-reassign
  dowOffset = typeof (dowOffset) === 'number' ? dowOffset : 0; // default dowOffset to zero
  const newYear = new Date(dateNow.getFullYear(), 0, 1);
  let day = newYear.getDay() - dowOffset; // the day of week the year begins on
  day = (day >= 0 ? day : day + 7);
  const daynum = Math.floor((dateNow.getTime() - newYear.getTime()
      - (dateNow.getTimezoneOffset() - newYear.getTimezoneOffset()) * 60000) / 86400000) + 1;
  let weeknum;
  if (day < 4) {
    weeknum = Math.floor((daynum + day - 1) / 7) + 1;
    if (weeknum > 52) {
      const nYear = new Date(dateNow.getFullYear() + 1, 0, 1);
      let nday = nYear.getDay() - dowOffset;
      nday = nday >= 0 ? nday : nday + 7;
      weeknum = nday < 4 ? 1 : 53;
    }
  } else {
    weeknum = Math.floor((daynum + day - 1) / 7);
  }
  if (weeknum > 10) {
    return weeknum;
  }
  return `0${weeknum}`;
}

export function getDefaultDateByType(period) {
  const dateNow = new Date();
  switch (parseInt(period, 10)) {
    case 0:
      return formatDate(dateNow, 'yyyy-MM-dd');
    case 1:
      return `${dateNow.getFullYear()}-W${getWeek()}`;
    case 2:
      return formatDate(dateNow, 'yyyy-MM');
    default:
      return '';
  }
}
// 将data转换为FormData
export function convertDataToFormData(obj = {}) {
  const formData = new FormData();
  const keys = Object.keys(obj);
  for (let i = 0; i < keys.length; i += 1) {
    const key = keys[i];
    formData.append(key, obj[key]);
  }
  return formData;
}
// 生成UUID
export function uuid() {
  const s = [];
  const hexDigits = '0123456789abcdef';
  for (let i = 0; i < 36; i += 1) {
    s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
  }
  // bits 12-15 of the time_hi_and_version field to 0010
  s[14] = '4';
  // bits 6-7 of the clock_seq_hi_and_reserved to 01
  // eslint-disable-next-line no-bitwise
  s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1);
  // eslint-disable-next-line no-multi-assign
  s[8] = s[13] = s[18] = s[23] = '-';
  return s.join('');
}
// 将数组转换成属树型结构
export function arrayToTree(arr, idInfo = {
  id: 'TabID',
  pId: 'PID',
}) {
  if (arr == null) {
    return [];
  }
  const { id, pId } = idInfo;
  const treeList = [];
  const map = {};
  for (let i = 0; i < arr.length; i += 1) {
    const tItem = arr[i];
    map[tItem[id]] = tItem;
  }
  for (let i = 0; i < arr.length; i += 1) {
    const tItem = arr[i];
    if (tItem[pId] === 0 || tItem[pId] === '0') {
      treeList.push(tItem);
    } else if (map[tItem[pId]]) {
      if (!map[tItem[pId]].children) {
        map[tItem[pId]].children = [];
      }
      map[tItem[pId]].children.push(tItem);
    }
  }
  return treeList;
}

export function turnAsking({ routeName, nowRouteName, func }, timeout = 60 * 1000) {
  if (routeName === nowRouteName) {
    return;
  }
  setTimeout(func, timeout);
}

export function getItemfromTree(tree, {
  propValue,
  prop,
  childProp,
}) {
  if (tree[prop] === propValue) {
    return tree;
  }
  if (tree[childProp]) {
    for (let i = 0; i < tree[childProp].length; i += 1) {
      const item = getItemfromTree(tree[childProp][i], {
        propValue,
        prop,
        childProp,
      });
      if (item) {
        return item;
      }
    }
  }
  return null;
}
// 动态监听
export function initResizeListener(chart, ref) {
  const resizeObserver = new ResizeObserver(() => {
    // 回调
    chart.resize();
  });
  // 监听对应的dom
  resizeObserver.observe(ref);
}
// 组件大小监听
export function addResizeListener(ref, cb) {
  const resizeObserver = new ResizeObserver(cb);
  resizeObserver.observe(ref);
}

export function getSelectCourtIds(info, type = 'byq') {
  let courtList = [];
  if (info.oType === type) {
    courtList.push(info.IdStr);
  } else {
    for (let i = 0; i < info.Children.length; i += 1) {
      const itemInfo = info.Children[i];
      courtList = [...courtList, ...getSelectCourtIds(itemInfo, type)];
    }
  }
  console.log(courtList);
  return [...new Set(courtList)];
}
